// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$admin-frontend-primary: mat.define-palette(mat.$indigo-palette);
$admin-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$admin-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-frontend-theme: mat.define-light-theme((
  color: (
    primary: $admin-frontend-primary,
    accent: $admin-frontend-accent,
    warn: $admin-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-frontend-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import "bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,900&display=swap&subset=latin-ext&display=swap');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@import 'ngx-toastr/toastr-bs4-alert';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

.snack-bar-rqa.mat-snack-bar-container {
  margin-bottom: 250px;
  margin-left: 100px;
  position: relative;
}

.snack-bar-rqa .mat-button-wrapper {
  color: #664399;
  font-weight: bold;
}

.snack-bar-rqa {
  background: #664399;
  font-weight: 500;
  color: white;
}

.toast-error {
  background-color: #BD362F;
}

.toast-success {
  background-color: green;
}

.toast-warning {
  background-color: sandybrown;
}

.margin-left-10 {
  margin-left: 10px;
}

.modal-backdrop {
  opacity: 0;
  transition: opacity .2s;

  &.in {
    opacity: .7;
  }
}


.modal-content {
  background-color: red;
}

.spinner {
  .modal-content {
    background-color: transparent;
    border: none;
  }

}

.ql-container {
  height: 500px
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  .modal {
    z-index: 1072;
  }
}

app-users {
  input[type=checkbox] {
    margin: 0 10px 10px;
  }
}
